import { Form, FormInstance, FormProps, Popconfirm } from 'antd'
import { Rule } from 'antd/lib/form'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormGroup } from '../../Form/FormGroup'
import CMSButton from '../../Styles/button.style'
import { RowBetween } from '../../Styles/row.style'

export interface FormField<T = unknown> {
  name?: keyof T
  label?: string | React.ReactNode
  rules?: Rule[]
  type?: 'field' | 'list'
  children: JSX.Element | JSX.Element[]
  initialValue?: T[keyof T]
  hidden?: boolean
  config?: {
    display?: {
      name?: boolean
      label?: boolean
    }
  }
}

export type FormFields<T extends any> = FormField<T>[]

export type FormType<T> = {
  title: string
  fields: FormFields<T>
}

export type FormTypes<T> = FormType<T>[]

export const FormItemGroup = <T extends any>({ formType }: { formType: FormType<T> }) => {
  const { title, fields } = formType
  return (
    <FormGroup label={title}>
      {fields.map(({ name, label, rules, children, initialValue }) => {
        const haveDisplayNone = children.props.style?.display === 'none'
        const itemStyle = haveDisplayNone ? { display: 'none' } : {}

        return (
          <Form.Item
            name={name as string}
            label={label}
            rules={rules || []}
            key={name as string}
            style={{ ...itemStyle }}
            initialValue={initialValue}
          >
            {children}
          </Form.Item>
        )
      })}
    </FormGroup>
  )
}

interface CMSActionsButtonsProps {
  form: FormInstance<any>
  cancelRedirectLink: string
  loading?: boolean
}

export const CMSActionsButtons = ({
  form,
  cancelRedirectLink,
  loading,
}: CMSActionsButtonsProps) => {
  const history = useHistory()
  return (
    <RowBetween>
      <Popconfirm
        title="Do You want to cancel/"
        onConfirm={() => history.replace(cancelRedirectLink)}
      >
        <CMSButton buttonColor="red" loading={loading}>
          Cancel
        </CMSButton>
      </Popconfirm>

      <CMSButton buttonColor="green" loading={loading} onClick={() => form.submit()}>
        Submit
      </CMSButton>
    </RowBetween>
  )
}

interface CMSFormProps<T> extends FormProps {
  formTypes: FormTypes<T>
  cancelRedirectLink: string
  buttonLoading?: boolean
}

export const CMSForm = <T extends any>({
  form,
  formTypes,
  cancelRedirectLink,
  buttonLoading,
  ...props
}: CMSFormProps<T>) => {
  return (
    <Form {...props} form={form} {...props}>
      {formTypes.map((element) => (
        <FormItemGroup formType={element} key={element.title} />
      ))}
      <CMSActionsButtons
        form={form as FormInstance<any>}
        cancelRedirectLink={cancelRedirectLink}
        loading={buttonLoading}
      />
    </Form>
  )
}
